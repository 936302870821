.RegisterContainer{
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Register{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f6ff;
}
.Register > div{
    width: fit-content;
}

.Register > div input{
    height: 50px;
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding-left: 10px;
}
.Register > div label{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;

}
.Register button{
    background-color: black;
    color: white;
    padding: 10px;
    margin: 20px;
}