.UserManagementContainer{
    height: 100vh;
    width: 100%;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.UserSearchContainer{
    background-color: #ffffff;
    width: 320px;
    align-self: center;
    height: 100%;
}
.UserList{
    height: 70px;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f6f6f6;
    border: 2px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
}
.UserList p:last-child{
    cursor: pointer;
}
.UserSearchContainer input{
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    margin-left: 5%;
    border: 2px solid black;
    outline: 0;
    align-self: center;
    padding: 5px;
}
