.HeaderContainer{
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    z-index: 1;
}

.HeaderContainer img{
    height: 100%;
    width: 100px;
    image-resolution: from-image 3000dpi;
}

.MenuContainer{
    width: auto;
    height: 50px;
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.MenuContainer p{
    margin-right: 10px;
    margin-left: 10px;
}

.mobileMenuContainer{
    position: absolute;
    right: 10px;
}

.mobileMenuList{
    position: absolute;
    z-index: 100;
    background-color: black;
    color: gold;
    width: 140px;
    height: auto;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 80px;
}

#small{
    position: absolute;
    top: -2px;
    right: 5px;
}
.shoppingContainer{
    display: flex;
    align-items: center;
    width: auto;
    position: absolute;
    right: 10px;
}


@media only screen and (max-width: 768px) {
    .MenuContainer{
        display: none;
    }
    #small{
        position: absolute;
        top: -10px;
        right: 0px; 
    }
}

@media only screen and (min-width: 768px) {
    .mobileMenuContainer{
        display: none;
    }
    .mobileMenuList{
        display: none;
    }
    .shoppingContainer{
        display: none;
    }
}