.ProductManagementContainer{
    width: 100%;
    height: 100vh;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
}
.ProductManagement{
    height: 100%;
    width: 320px;
    align-self: center;
    overflow-y: scroll;
    background-color: #fff;
}
.ProductManagement > div{
    height: 400px;
    width: 96%;
    align-self: center;
    background-color: #f6f6ff;
    border: 2px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2%;

}
.ImgContainer{
    height: 200px;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: center;
}
.ImgContainer img{
    height: 90%;
    margin-left: 10px;
    margin-right: 10px;
    width: 200px;
}

