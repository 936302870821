.SuperAdmin{
    height: 100vh;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.ActionBar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ActionBar button{
    height: 50px;
    width: auto;
    padding: 10px;
    background-color: black;
    color: #fff;
}