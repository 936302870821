@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-VariableFont_wght.7e687123cd4528224ce5.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'AlexBrush';
    src: url('../assets/fonts/AlexBrush-Regular.2931994a26f7d46dab66.ttf') format('truetype');
    font-style: normal;
}

.FeatureContainer{
    height: auto;
    color: white;
    width: 95%;
    background-color: black;
    font: 'AlexBrush', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.FeatureContainer img{
    height: 150px;
    width: 250px;
}
#subheading{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 20px;
    word-wrap: break-word;
    padding: 10px;
}

#heading{
    color: goldenrod;
    font-size: 24px;
    padding: 10px;
}
@media only screen and (min-width: 1024px) {
    .FeatureContainer{
        margin-left: 10px;
        margin-right: 10px;
    }
    #heading{
        font-weight: 700;
    }
}