.PaymentsContainer{
    height: 100vh;
    background-color: #f6f6ff;
    width: 100%;
}
.PaymentsInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
}
.PaymentsInfo h2{
    font-size: 24px;
    font-weight: 500;
}

.PaymentsInfo input{
    height: 50px;
    border: 1px solid black;
    outline: 0;
}
.PaymentsInfo button{
    background-color: black;
    padding: 10px;
    color: white;
}
.Withdrawals{
    flex: 1;
}

.Withdrawals div{
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: black;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}