.CartContainer{
    height: 100%;
    width: 100%;
    background-color:#fff;
}
.cartItem{
    height: 100px;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f6f6f6ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.cartItem img{
    height: 80%;
    width: 100px;
}
.cartItem p{
    width: 30px;
}

.checkOut{
    position: absolute;
    bottom: 0;
    background-color: #f6f6f6ff;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.itemsContainer{
    height: 70vh;
    overflow-y: scroll;
    width: 100%;
}
.checkOut button{
    background-color: black;
    color:#ffff;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}
.CheckOutContainer{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CheckOutContainer > div{
    height: 80px;
    width: 300px;
    background-color: #f6f6f6ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.CheckOutContainer div:nth-child(3){
    background-color: #f6f6f6ff;
    height: 250px;
}
.CheckOutContainer div input{
    width: 80%;
    height: 50px;
    padding: 10px;
    border: 1px solid black;
    outline: 0;
}
.CheckOutContainer button{
    height: 50px;
    background-color: black;
    padding: 10px;
    width: 70px;
    border-radius: 10px;
    color: white;
}

@media only screen and (min-width: 600px) {
    .itemsContainer{
        height: 78vh;
    }
}

.confirmPayments{
    position: absolute;
    z-index: 1;
    min-height: 100%;
    min-width: 100%;
    color: blue;
}