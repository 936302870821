.SelectContainer{
    height: 50px;
    width: 150px;
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    font-size: 18px;
    justify-content: center;
}
.selectDisplay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    position: relative;
}
.selectDropDown{
    position: absolute;
    margin-top: 50px;
    width: 100px;
    z-index: 1;
    height: auto;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selectDropDown p{
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    color: white;
}