.DashboardContainer{
    background-color: #f6f6ff;
    width: 100%;
    height: 100vh;
}
.DashboardContent{
    width: 100%;
}
.DashboardContent > div{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}