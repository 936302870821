.FooterContainer{
    height: 200px;
    width: 100%;
    background-color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.FooterContainer p:first-child{
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}
.FooterContainer p:nth-child(2){
    font-weight: 400;
}