.ShopContainer{
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}
.ProductContent{
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ProductView{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.ImagesHolder{
    height: 250px;
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.ImagesHolder img{
    height: 100%;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid black;
}
.InfoAction{
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
#back{
    position: relative;
    top: 10px;
    left: 10px;
}

@media only screen and (max-width: 1024px) {
    
}

.Filters{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.productListedContainer{
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 100%;
}

.productListed{
    height: 300px;
}

.productListed img{
    height: 70%;
    width: 100%;
    border: 1px solid black;
}
.productDetails{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    text-align: center;
    justify-content: space-evenly;
    background-color: black;
    color: white;
}
.productDetails > div{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}



@media only screen and (max-width: 600px) {
    .productListedContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
        background-color: #fff;
    }
    .productListed{
        width: 90%;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #fff;
    }
}
@media only screen and (min-width: 600px) {
    .productListedContainer{
        width: 100%;
        height: 100%;
        display: flex;
    }
    .productListed{
        width: 200px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

