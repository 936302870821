@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-VariableFont_wght.7e687123cd4528224ce5.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'AlexBrush';
    src: url('../assets/fonts/AlexBrush-Regular.2931994a26f7d46dab66.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
  
.LandingPageContainer{
    height: 100vh;
    width: 100%;
}
.landingPageWelcome{
    height: auto;
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}
.landingPageInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    height: auto;
}
#welcome{
    font-family: 'AlexBrush', sans-serif;
    font-size: 70px;
    color: goldenrod;
    font-weight: 900;
    padding: 10px;
}
#moreOnWelcome{
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: white;
}
.Redirectbutton{
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    background-color: black;
    color: white;
    border: 1px solid white;
}
.landingPageWelcome button:hover{
    scale: 1.02;
}
.socials{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.socials div{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.socials p{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
}

#Features{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#Features::before {
    content: "";
    display: block;
    height: 10px; /* Adjust this value as needed */
    /* margin-top: -10px; Same value as height */
    visibility: hidden;
}

#smallHeading{
    font: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 500;
    background-color: black;
    color: goldenrod;
    padding: 5px;
}

@media only screen and (max-width: 1024px) {
    .landingPageWelcome{
        background-image: url('../assets/images/pigalooklogo.jpg');
        background-size: cover;
    }
    .landingPageInfo{
        height: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        justify-items: center;
        background-image: url('../assets/images/pigalooklogo.jpg') no-repeat center center;
        background-size: cover;
        position: relative;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .backgroundHolder{
        display: none;
    }
    #welcome{
        font-size: 70px;
        padding: 10px;
    }
    #moreOnWelcome{
        text-align: center;
        padding: 10px;
    }
    #Features{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #Features::before {
        content: "";
        display: block;
        height: 60px;
        visibility: hidden;
    }
}

@media only screen and (min-width: 1024px) {
    .landingPageWelcome{
        width: 100%;
        display: flex;
        align-items: center;
    }
    #welcome{
        font-size: 130px;
        font-weight: bold;
    }
    #moreOnWelcome{
        font-weight: 600;
        padding: 15px;
    }
    .backgroundHolder{
        width: 80vw;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .backgroundHolder img{
       width: 100%;
       height: auto;
       image-rendering: optimizeQuality;
       background-color: rgba(0, 0, 0, 0.8);
    }
    #Features{
        display: flex;
        flex-direction: row;
        height: auto;
    }
    #Features::before {
        content: "";
        display: block;
        height: 10px; /* Adjust this value as needed */
        /* margin-top: -10px; Same value as height */
        visibility: hidden;
    }
      
    
}
