.LogInContainer{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
}
.LogInForm{
    height: 400px;
    width: 290px;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.LogInForm > div{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LogInForm > div > input{
    border: 1px solid black;
    height: 35px;
}
.LogInForm > div > label{
    text-align: left;
}

.LogInForm > input{
    padding: 20px;
    background-color: black;
    color: white;
    font-size: 18px;
    cursor: pointer;
}
