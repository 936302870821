.UploadedContainer{
    height: 100vh;
    width: 100%;
    background-color: #f6f6ff;
}
.ProductViewContainer{
    height: 100%;
    width: 100%;
}

.ProductViewContainer div{
    width: 100%;
    height: 100px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: scroll;
}

.ProductViewContainer div img{
    height: 100px;
    width: 100px;
    border: 1px solid black;
}

.ProductViewContainer div p{
    width: 100px;
    color: white;
}

.ProductViewContainer div button{
    width: 100px;
    margin: 0;
    background-color: black;
    color: gold;
}