.ImageDisplayer{
    height: 200px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: center;
}
.ImageDisplayer img{
    height: 90%;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
}