.OrderContainer{
    height: 100vh;
    width: 285px;
    background-color: #ffff;
    margin-bottom: 10px;
}
.OrderProductContainer{
    height: 450px;
    overflow-x: scroll;
}
.OrderProducts{
    display: block;
    flex-direction: row;
    align-items: center;
    height: 250px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid black;
}
.ImageHolder{
    height: 70%;
    display: flex;
    width: auto;
}
#img{
    flex-direction: column;
    align-items: center;
    height: 150px;
    width: auto;
}
#img img{
    height: 100%;
    width: 100%;
}
.ImageHolder p{
    width: 200px;
    text-align: center;
}

.RecepientInfo{
    height: 60px;
    margin-top: 10px;
    width: 90%;
    margin-left: 5%;
    background-color: #f6f6ff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.RecepientInfoContainer{
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow-y: scroll;
}
.OrderProducts button{
    margin: 10px;
    height: 70px;
    width: 90px;
    background-color: blue;
}