@font-face {
    font-family: 'AlexBrush';
    src: url('../assets/fonts/AlexBrush-Regular.2931994a26f7d46dab66.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-VariableFont_wght.7e687123cd4528224ce5.ttf') format('truetype');
    font-style: normal;
}

.AboutContainer{
    height: 100vh;
    width: 100vw;
    background-color: #f6f6f6;
    overflow-y: scroll;
}
#uncover{
    font-size: 54px;
    text-align: center;
    font-family: 'AlexBrush', 'san-serif';
}
.welcome p:first-child{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 800;
    padding: 10px;
    color: blue;
}

.welcome p:last-child{
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
}

.whyus p:first-child{
    font-family: 'AlexBrush';
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: blue;
}
.whyus p:last-child{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    padding: 10px;
    text-align: left;
}
.moreInfo{
    height: auto;
    background-color: #f6f6ff;
    margin: 10px;
    padding: 10px;
}
.moreInfo p{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}