.UploadContainer{
    background-color: #ffff;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.UploadButton{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ImagesUpload{
    height: 100%;
    width: 300px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ImagesUpload button{
    background-color: black;
    color: white;
    font-size: 18px;
    padding: 10px;
}
.ImagesUpload div{
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}
.ImagesUpload label{
    padding: 10px;
}
.ImagesUpload input{
    border: 1px solid black;
    outline: black;
}
.Upload{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}