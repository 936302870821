@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.7e687123cd4528224ce5.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AlexBrush';
  src: url('./assets/fonts/AlexBrush-Regular.2931994a26f7d46dab66.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Montserrat',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  padding: 0;
  margin: 0;
  font-family: 'Montserrat',sans-serif;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

